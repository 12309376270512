import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const VentesLyneProductSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  padding: 0px 20px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0px 10px;
  }
`

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 20px 0px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 10px 0px 20px 0px;
  }
`

const ProductBox = styled.div`
  width: 100%;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: #262626;

  :last-of-type {
    margin: 10px 0px 10px 0px;
  }

  :nth-of-type(2) {
    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  @media (max-width: 767px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(50% - 5px);
  }
`

const ProductBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: #262626;
`

const ColorBox = styled(Link)`
  width: 100%;
  margin: 0px 15px 0px 0px;
  padding: 15px;
  display: block;
  background: ${({ name }) =>
    name === "Rose"
      ? "#efc9c9"
      : name === "Sable"
      ? "#efeec9"
      : name === "Gris"
      ? "#c4c4c4"
      : "#262626"};
  max-width: 30px;

  :last-of-type {
    margin: 0px;
  }

  @media (max-width: 767px) {
    margin: 0px 10px 0px 0px;
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const ButtonTitle = styled.span`
  display: none;
`

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const HiddenImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  visibility: hidden;
  opacity: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
`

const ImageText = styled.div`
  background: #fff;
  color: #262626;
  font-size: 12px;
  line-height: 1;
  font-family: "Gotham";
  font-weight: bold;
  position: absolute;
  bottom: 15px;
  right: 15px;
  max-width: calc(100% - 30px);
  margin: 0px;
  padding: 7px 10px 5px;
  border-radius: 5px;

  span {
    display: block;
  }

  @media (max-width: 380px) {
    padding: 6px 7px 5px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0px 0px;
`

const ColorWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px 0px;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
`

const DiscountText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  color: ${props => props.theme.saleColor.textColor};
  background: ${props => props.theme.saleColor.bgColor};
  padding: 5px 5px 2px;
  // color: ${props => props.theme.saleColor.bgColor};
`

const CustomBox = styled.div`
  width: 100%;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background: #e7f0f3;
  color: #262626;

  :last-of-type {
    margin: 10px 0px 10px 0px;

    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  :nth-of-type(2) {
    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  @media (max-width: 767px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(50% - 5px);
  }
`

const CustomBoxWrapper = styled.div`
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #262626;
  padding: 10px;
  margin: 10px;

  @media (max-width: 480px) {
    height: calc(100% - 10px);
    padding: 5px;
    margin: 5px;
  }
`

const CustomTitle = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 22px;
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 140px;
  margin: 40px 0px;
  object-fit: contain;

  @media (max-width: 1600px) {
    max-width: 120px;
    margin: 30px 0px;
  }

  @media (max-width: 1200px) {
    max-width: 80px;
    margin: 20px 0px;
  }

  @media (max-width: 992px) {
    max-width: 75px;
    margin: 10px 0px;
  }

  @media (max-width: 420px) {
    max-width: 60px;
    margin: 5px 0px;
  }
`

const CustomText = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    font-size: 12px;
  }
`

const VentesLyneProductSection = ({ intl, data }) => {
  const [hoverImgArr, sethoverImgArr] = useState([])

  const onMouseOver = (image_index, image_url) => {
    var newArr = []
    hoverImgArr.forEach((item, index) => {
      if (image_index === index) {
        item.active = true
        item.image = image_url
      }
      newArr.push(item)
    })
    sethoverImgArr(newArr)
  }

  const onMouseOut = image_index => {
    var newArr = []
    hoverImgArr.forEach((item, index) => {
      if (image_index === index) {
        item.active = false
      }
      newArr.push(item)
    })
    sethoverImgArr(newArr)
  }

  useEffect(() => {
    let hoverArr = []
    data.list &&
      data.list.forEach((item, index) => {
        let newListImage = {}
        newListImage.image = item.image
        newListImage.active = false
        hoverArr.push(newListImage)
      })
    sethoverImgArr(hoverArr)
  }, [])

  return (
    data && (
      <VentesLyneProductSectionWrapper>
        <VentesContainer>
          {data.list && (
            <RowWrapper>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <ProductBox key={index.toString()}>
                      <ProductBoxWrapper>
                        {item.url && (
                          <AbsoluteButton to={item.url}>
                            <ButtonTitle>Découvrir</ButtonTitle>
                          </AbsoluteButton>
                        )}
                        <ImageBox>
                          {hoverImgArr[index] &&
                          hoverImgArr[index].active &&
                          hoverImgArr[index] ? (
                            <Image
                              src={hoverImgArr[index].image}
                              alt={item.alt ? item.alt : ""}
                            />
                          ) : (
                            <Image
                              src={item.image}
                              alt={item.alt ? item.alt : ""}
                            />
                          )}
                          {item.text && (
                            <ImageText
                              dangerouslySetInnerHTML={{
                                __html: item.text,
                              }}
                            />
                          )}
                        </ImageBox>
                        <TextWrapper>
                          {data.card_title && (
                            <Title
                              dangerouslySetInnerHTML={{
                                __html: data.card_title,
                              }}
                            />
                          )}
                          {data.discount_text && (
                            <DiscountText
                              dangerouslySetInnerHTML={{
                                __html: data.discount_text,
                              }}
                            />
                          )}
                        </TextWrapper>
                      </ProductBoxWrapper>
                      {item.color_data && (
                        <ColorWrapper>
                          {item.color_data.map((colorItem, colorIndex) => {
                            return (
                              colorItem && (
                                <ColorBox
                                  key={colorIndex.toString()}
                                  to={colorItem.url}
                                  name={colorItem.name}
                                  // active={
                                  //   color_item.color === item.color
                                  //     ? "active"
                                  //     : ""
                                  // }
                                  onMouseOver={() =>
                                    onMouseOver(index, colorItem.image)
                                  }
                                  onMouseOut={() => onMouseOut(index)}
                                >
                                  {colorItem.name && (
                                    <ButtonTitle>{colorItem.name}</ButtonTitle>
                                  )}
                                </ColorBox>
                              )
                            )
                          })}
                        </ColorWrapper>
                      )}
                    </ProductBox>
                  )
                )
              })}
              <CustomBox>
                {data.url && (
                  <AbsoluteButton to={data.url}>
                    <ButtonTitle>Découvrir</ButtonTitle>
                  </AbsoluteButton>
                )}
                <HiddenImage
                  className="lazyload"
                  data-src="https://static.percko.com/uploads/453e44a9-7d87-43ca-8475-504816153eb9.jpg"
                  alt=""
                />
                <CustomBoxWrapper>
                  {data.title && (
                    <CustomTitle
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    />
                  )}
                  {data.logo && (
                    <LogoImage
                      className="lazyload"
                      data-src={data.logo}
                      alt={data.alt ? data.alt : ""}
                    />
                  )}
                  {data.text && (
                    <CustomText
                      dangerouslySetInnerHTML={{
                        __html: data.text,
                      }}
                    />
                  )}
                </CustomBoxWrapper>
              </CustomBox>
            </RowWrapper>
          )}
        </VentesContainer>
      </VentesLyneProductSectionWrapper>
    )
  )
}

export default injectIntl(VentesLyneProductSection)
